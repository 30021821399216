const yttc200 = [
  {
    dates: "11 October to 3 November 2023",
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  {
    dates: "23 October to 15 November 2023",
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  {
    dates: "4 November to 27 November 2023",
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  {
    dates: "16 November to 9 December 2023",
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  {
    dates: "28 November to 21 December 2023",
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  {
    dates: "10 December to 2 January 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  {
    dates: "22 December to 14 January 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  {
    dates: "3 January to 26 January 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
  },
];

const yttc100hathaAshVin = [
  {
    dates: "28 November to 9 December 2023",
    private: "EUR 900",
    shared: "EUR 650",
  },
  {
    dates: "22 December to 2 January 2024",
    private: "EUR 900",
    shared: "EUR 650",
  },
  {
    dates: "15 January to 26 January 2024",
    private: "EUR 900",
    shared: "EUR 650",
  },
];

const yttc100AerialYin = [
  {
    dates: "16 November to 27 November 2023",
    private: "EUR 1100",
    shared: "EUR 800",
  },
  {
    dates: "10 December to 21 December 2023",
    private: "EUR 1100",
    shared: "EUR 800",
  },
  {
    dates: "3 January to 14 January 2024",
    private: "EUR 1100",
    shared: "EUR 800",
  },
];

const yttc50Hatha = [
  {
    dates: "25 August to 01 September 2024",
    private: "EUR 700",
    shared: "EUR 500",
  },
  {
    dates: "15 September to 22 September 2024",
    private: "EUR 700",
    shared: "EUR 500",
  },
];

const yttc50AerialAcro = [
  {
    dates: "29 September to 06 October 2024",
    private: "EUR 700",
    shared: "EUR 500",
  },
];

const yttc50Ashtanga = [
  {
    dates: "01 September to 08 September 2024",
    private: "EUR 700",
    shared: "EUR 500",
  },
  {
    dates: "22 September to 29 September 2024",
    private: "EUR 700",
    shared: "EUR 500",
  },
];

const yttc15days100HathaAshtanga = [
  {
    dates: "15 September to 29 September 2024",
    private: "EUR 1200",
    shared: "EUR 900",
  },
];

const yttc15days100AshtangaAerial = [
  {
    dates: "01 September to 15 September 2024",
    private: "EUR 1200",
    shared: "EUR 900",
  },
  {
    dates: "22 September to 06 October 2024",
    private: "EUR 1200",
    shared: "EUR 900",
  },
];

const yttc22days200HathaAshtangaAerialAcro = [
  {
    dates: "25 August to 15 September 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "01 September to 22 September 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "08 September to 29 September 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "15 September to 06 October 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "22 September to 13 October 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "29 September to 20 October 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "06 October to 27 October 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "13 October to 3 November 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "20 October to 10 November 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "27 October to 17 November 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "3 November to 24 November 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "10 November to 1st December 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "17 November to 8 December 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "24 November to 15 December 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "1 December to 22 December 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "8 December to 29 December 2024",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "15 December to 5 Janauary 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "22nd December to 12 January 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "29 December 2024 to 19 January 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "5 January to 26 January 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "12 January to 2nd February 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "19 January to 9 February 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "26 January to 16 February 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "2nd February to 23 February 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "9 February to 2 March 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "16 February to 9 March 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "2 February to 16 March 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "2 March to 23 March 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "9th March to 30 March 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "16 March to 6 April 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "23 March to 13 April 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "30 March to 20 April 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
  {
    dates: "6 April to 27 April 2025",
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1100",
  },
];

const yogaHolidaysBaliDates = [
  {
    dates: "Custom Dates, enter your dates in Special request section",
    private: "EUR 700",
    shared: "EUR 500",
  },
];

const yttc200Bali200Puri = [
  {
    dates: "18 August 8 September 2024",
    private: "EUR 1500",
    shared: "EUR 1100",
  },
  {
    dates: "8 September to 29 September 2024",
    private: "EUR 1500",
    shared: "EUR 1100",
  },
  {
    dates: "29 September 20 October 2024",
    private: "EUR 1500",
    shared: "EUR 1100",
  },
  {
    dates: "20 October 2024 10 November 2024",
    private: "EUR 1500",
    shared: "EUR 1100",
  },
  {
    dates: "10 November 1 December 2024",
    private: "EUR 1500",
    shared: "EUR 1100",
  },
  {
    dates: "1 December 22 December 2024",
    private: "EUR 1500",
    shared: "EUR 1100",
  },
];

export {
  yttc200,
  yttc100hathaAshVin,
  yttc100AerialYin,
  yttc50Hatha,
  yttc50AerialAcro,
  yttc50Ashtanga,
  yttc15days100HathaAshtanga,
  yttc15days100AshtangaAerial,
  yttc22days200HathaAshtangaAerialAcro,
  yogaHolidaysBaliDates,
  yttc200Bali200Puri,
};
